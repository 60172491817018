<template>
  <VContainer
    fluid
  >
    <VForm
      ref="form"
      v-model="valid"
      lazy-validation
      :disabled="loading"
    >
      <VRow>
        <VCol
          cols="12"
        >
          <div class="d-flex justify-space-between">
            <h1
              data-test="changelog-form-title"
              class="tt-text-subtitle"
            >
              {{ edit ? 'Редактирование' : 'Новая запись' }}
            </h1>
            <TTBtn
              fab
              small
              depressed
              color="transparent"
              :ripple="false"
              :disabled="loading"
              data-test="changelog-form-close"
              @click="handleClose"
            >
              <VIcon
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
          </div>
        </VCol>
        <VCol cols="12">
          <TTTextField
            v-model="entity.title"
            large
            label="Заголовок"
            :rules="rulesTitle"
            data-test="changelog-form-input-title"
          />
        </VCol>
        <VCol cols="12">
          <TTTextField
            v-model="entity.sub_title"
            large
            label="Подзаголовок"
            :rules="rulesSubtitle"
            data-test="changelog-form-input-subtitle"
          />
        </VCol>
        <VCol cols="12">
          <SEditor
            ref="editor"
            height="300"
            data-test="changelog-form-editor"
            image
            :error="!!errorText"
            :error-messages="errorText"
            @text-change="entity.text = editor.getHtml()"
          />
        </VCol>
        <VCol cols="12">
          <div class="d-flex justify-end">
            <TTBtn
              large
              color="tt-secondary"
              depressed
              class="mr-6"
              :disabled="loading"
              data-test="user-form-button-cancel"
              @click="handleClose"
            >
              Отменить
            </TTBtn>
            <TTBtn
              depressed
              large
              :loading="loading"
              data-test="user-form-button-save"
              @click="save"
            >
              {{ edit ? 'Сохранить' : 'Опубликовать новость' }}
            </TTBtn>
          </div>
        </VCol>
      </VRow>
    </VForm>
  </VContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  validateRules, validateCustomRules,
} from '@/services';
import SEditor from '@/components/editor/SEditor.vue';

export default {
  name: 'ChangelogForm',
  components: { SEditor },
  props: {
    editEntity: {
      type: Object,
      default: () => {
      },
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      entity: {
        title: '',
        sub_title: '',
        text: '',
      },
      loading: false,
      valid: false,
      rulesTitle: [
        validateRules.REQUIRED(),
        validateRules.MIN_LEN(5),
        validateRules.MAX_LEN(300),
      ],
      rulesSubtitle: [
        validateRules.MIN_LEN(5),
        validateRules.MAX_LEN(100),
      ],
      rulesText: [
        validateRules.REQUIRED(),
        validateRules.MIN_LEN(5),
      ],
      errorText: null,
    };
  },
  computed: {
    ...mapGetters('acl', ['userId']),
    editor() {
      return this.$refs.editor;
    },
  },
  watch: {
    'entity.text': {
      handler() {
        this.validateText();
      },
    },
  },
  mounted() {
    if (this.edit) {
      this.handleEntity();
    }
  },
  methods: {
    ...mapActions('acl', ['getUser']),
    async save() {
      this.validate();
      this.validateText();
      if (this.valid && !this.errorText) {
        this.loading = true;
        const data = { ...this.entity };
        try {
          const { adminChangelog } = this.$repositories;
          const handler = this.edit ? adminChangelog.update : adminChangelog.create;
          await handler({
            data,
          });
          this.$emit('save');
        } catch (error) {
          this.$root.$emit('showNotifier', {
            title: 'Ошибка сохранения',
            timeout: 2000,
            type: 'error',
          });
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    handleEntity() {
      if (this.editEntity.text) {
        this.editor.insertRawHtml(this.editEntity.text);
      }
      this.entity = { ...this.entity, ...this.editEntity };
    },
    handleClose() {
      this.$emit('close');
    },
    checkCurrentUser(id) {
      return this.userId === id;
    },
    validate() {
      this.$refs.form.validate();
    },
    validateText() {
      const errors = validateCustomRules(this.rulesText, this.entity.text);
      this.errorText = errors.length ? errors[0] : null;
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
