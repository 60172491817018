<template>
  <VContainer fluid>
    <VRow>
      <VCol>
        <h1 class="tt-text-headline-1">
          Управление историей изменений
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="6"
      >
        <HistoryCard
          v-for="change in changelog"
          :key="change.id"
          :title="change.title"
          :description="change.text"
          :date="change.pub_date"
          :subtitle="change.sub_title"
          menu
          @toggleEdit="onEdit(change)"
          @toggleDelete="onDelete(change)"
        />
      </VCol>
    </VRow>
    <DialogWrapper
      v-model="showModalForm"
      width="676"
      attach
      @click:outside="closeModalForm"
    >
      <ChangelogForm
        v-if="showModalForm"
        :edit="edit"
        :edit-entity="activeEntity"
        @close="closeModalForm"
        @save="handleSave"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="showModelDelete"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="closeModalDelete"
    >
      <DeleteForm
        @delete="handleDelete"
        @cancel="closeModalDelete"
      >
        <h3 class="delete-form__title">
          Удалить?
        </h3>
        <p class="mb-1">
          Все даные будут удалены.
        </p>
      </DeleteForm>
    </DialogWrapper>
  </VContainer>
</template>
<script>
import HistoryCard from '@/components/cards/HistoryCard.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import ChangelogForm from '@/components/forms/ChangelogForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Changelog',
  components: {
    HistoryCard,
    DialogWrapper,
    ChangelogForm,
    DeleteForm,
  },
  data() {
    return {
      edit: false,
      activeEntity: {},
      changelog: [],
      showModalForm: false,
      showModelDelete: false,
      changelogIdDelete: 0,
    };
  },
  mounted() {
    this.load();
    this.$root.$on('showChangelogContentForm', this.openModalForm);
  },
  beforeDestroy() {
    this.$root.$off('showChangelogContentForm', this.openModalForm);
  },
  methods: {
    ...mapActions('changelog', ['getChangelog']),
    openModalForm() {
      this.showModalForm = true;
    },
    closeModalForm() {
      this.edit = false;
      this.activeEntity = {};
      this.showModalForm = false;
    },
    openModalDelete(changelogId) {
      this.changelogIdDelete = changelogId;
      this.showModelDelete = true;
    },
    closeModalDelete() {
      this.changelogIdDelete = 0;
      this.showModelDelete = false;
    },
    handleSave() {
      this.load();
      this.closeModalForm();
    },
    async handleDelete() {
      try {
        await this.$repositories.adminChangelog.delete({
          data: {
            id: this.changelogIdDelete,
          },
        });
        this.load();
        this.closeModalDelete();
        this.getChangelog();
      } catch (e) {
        console.error(e);
      }
    },
    async load() {
      try {
        const { data } = await this.$repositories.adminChangelog.list();
        this.changelog = data;
        this.getChangelog();
      } catch (e) {
        console.error(e);
      }
    },
    onEdit(change) {
      this.edit = true;
      this.activeEntity = change;
      this.openModalForm();
    },
    onDelete(change) {
      this.openModalDelete(change?.id);
    },
  },
};
</script>
